import React from 'react';
import Layout from '../../components/Layout';
import ProjectHeader from '../../components/ProjectHeader';
import TimerIntro from '../../components/ProductivityTimer/TimerIntro'
import TimerContent from '../../components/ProductivityTimer/TimerContent'
import Footer from '../../components/Footer';

const IndexPage = () => (
  <Layout>
    <ProjectHeader />
    <TimerIntro />
    <TimerContent />
    <Footer />
  </Layout>
);

export default IndexPage;
