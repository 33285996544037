import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Box, Flex } from 'rebass';
import Fade from 'react-reveal/Fade';
import ReactMarkdown from 'react-markdown';
import { ProjectContentCard } from '../ProjectContentCard';
import Section from '../Section';
import markdownRenderer from '../MarkdownRenderer';
import SeeProjects from '../../sections/SeeProjects';
import {
  ProjectImageLeft,
  ProjectImageRight,
  ProjectImageTransparent,
} from '../ProjectImage';
import ProjectTitle from '../ProjectTitle';

const TimerContent = () => (
  <Section.Container id="Timer Content">
    <StaticQuery
      query={graphql`
        query TimerContent {
          contentfulProductivityTimer {
            introduction {
              introduction
            }
            challenge {
              challenge
            }
            solution {
              solution
            }
            contentPictures {
              file {
                url
                fileName
              }
              id
              title
            }
          }
        }
      `}
      render={({ contentfulProductivityTimer }) => {
        const animationGif = contentfulProductivityTimer.contentPictures.filter(
          object => object.title === 'MacBook_Animation',
        );
        const imgTimer1 = contentfulProductivityTimer.contentPictures.filter(
          object => object.title === 'mobile Timer howItWorks',
        );
        const imgTimer2 = contentfulProductivityTimer.contentPictures.filter(
          object => object.title === 'Screens Timer',
        );
        const imgProtagonist = contentfulProductivityTimer.contentPictures.filter(
          object => object.title === 'Protagonist',
        );

        return (
          <Fade bottom>
            <ProjectContentCard>
              <Flex justifyContent="top" flexWrap="wrap">
                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Introduction</ProjectTitle>
                    <ReactMarkdown
                      source={
                        contentfulProductivityTimer.introduction.introduction
                      }
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]} mt={(2, 2, -90)}>
                  {animationGif.map(img => (
                    <ProjectImageTransparent src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 1 / 3]}>
                  {imgTimer1.map(img => (
                    <ProjectImageTransparent src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 2 / 3]}>
                  <Box width={[1, 1, 2 / 3]} px={[2, 3, 4]}>
                    <Fade bottom>
                      <ProjectTitle>Challenge</ProjectTitle>
                      <ReactMarkdown
                        source={contentfulProductivityTimer.challenge.challenge}
                        renderers={markdownRenderer}
                      />
                    </Fade>
                  </Box>

                  <Box width={[1, 1, 1]}>
                    {imgProtagonist.map(img => (
                      <ProjectImageTransparent
                        src={img.file.url}
                        key={img.id}
                      />
                    ))}
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Solution</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulProductivityTimer.solution.solution}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  {imgTimer2.map(img => (
                    <ProjectImageTransparent src={img.file.url} key={img.id} />
                  ))}
                </Box>
              </Flex>
            </ProjectContentCard>

            <SeeProjects />
          </Fade>
        );
      }}
    />
  </Section.Container>
);

export default TimerContent;
